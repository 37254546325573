<template>
  <div class="about">

    <van-nav-bar
        title="排尿日记"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >

     <template #left>
       <van-icon name="arrow-left" color="black"  size="20px"/>
     </template>

    </van-nav-bar>
    

    <div v-if="!loading">
      <div v-if="log_list.date === ''" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        暂无数据
      </div>
        
      <div v-else style="padding : 10px">

        <van-row>
          <van-col span="12">日期: {{log_list.date}}</van-col>
          <van-col span="12" style="text-align:right" @click="onClickSleepModifyBtn">修改</van-col>
        </van-row>

        <div class="usrInfo">
        <div>
          <span>
            {{ log_list.patientInfo.name }}
          </span>
          <span>|</span>
          <span>
            {{ log_list.patientInfo.sexy }}
          </span>
          <span>|</span>
          <span>
            {{ log_list.patientInfo.bri }}岁
          </span>
        </div>

        <div>
          <span>入睡时间: {{ log_list.sleep_in }}</span>
          <span>|</span>
          <span>起床时间: {{ log_list.get_up }}</span>
        </div>

        <div>
          <span>排尿次数: {{ log_list.uri_list.length }} </span>
          <span>饮水次数: {{ log_list.drink_list.length }}</span>
        </div>
        </div>

        <div>
        <van-tabs v-model:active="tabNumber" color='#1989fa' title-active-color='#1989fa' style="margin-top:10px">
          <van-tab title="排尿情况">
            <hr>
            <van-button type="default" size="large" @click="onClickAddBtn">新增排尿</van-button>
            <hr>

            <div v-for="(item,index) in log_list.uri_list" :key="item.id">
              <div class="urineItem">
                <div>
                <span>时间: {{ item.time }}</span>
                <span>尿量: {{ item.urine_volume }}</span>
                <span class="modify" @click="onClickItemModifyBtn(index)">修改</span>
                </div>

                <div>
                <span>尿急评分: {{ item.urgency_score }}</span>
                <span>漏尿情况: {{ item.urine_leakage == '1' ? '是' : '否' }}</span>
                </div>

                <div>
                <span>备注: {{ item.tips }}</span>
                <span class="modify" style="color:rgb(255,0,0)" @click="onRemoveUrineBtnClicked(item)">删除</span>
                </div>

              </div>
              <hr>
            </div>
          </van-tab>
          <van-tab title="饮水情况">
            <hr>
            <van-button type="default" size="large" @click="onClickAddBtn">新增饮水</van-button>
            <hr>

            <div v-for="(item,index) in log_list.drink_list" :key="item.id">
              <div class="drinkItem">
                <span>时间: {{ item.time }}</span>
                <span>类型: {{ item.drink_type }}</span>
                <span>饮水量: {{ item.drink_volume }}</span>
                <!-- <span @click="onRemoveDrinkBtnClicked(item)">删除</span> -->
                <span class='modify' @click="onClickItemModifyBtn(index)">修改</span>
                <div>
                  <span>备注: {{ item.tips }}</span>
                  <span class='modify' style="color:rgb(255,0,0)" @click="onRemoveDrinkBtnClicked(item)">删除</span>
                </div>
                <hr>
              </div>
            </div>
            
          </van-tab>
        </van-tabs>

        <van-dialog v-model:show="dialogDisplayType[tabNumber]" 
                    :title="isModify ? dialogModifyTitle[tabNumber] : dialogDisplayTitle[tabNumber]" 
                    @confirm="isModify ? onModifyBtnConfirm() : onAddBtnConfirm()"
                    @cancel="usrUrineInfo.selfClear" 
                    show-cancel-button>

              <div v-if="dialogDisplayType[0]">
                <br>
                <time-picker 
                label="时间选择"
                placeholder="点击选择时间"
                v-model:time="usrUrineInfo.time">
                </time-picker>

                <van-cell-group inset>
                 
                <van-field
                   autosize
                   rows="1"
                   label="尿量"
                   type="textarea"
                   placeholder="请输入尿量"
                   show-word-limit
                   label-align="center"
                   v-model="usrUrineInfo.urine_volume"
                 />

                <van-field
                   autosize
                   rows="1"
                   label="尿急评分"
                   type="textarea"
                   placeholder="请输入尿急评分"
                   show-word-limit
                   label-align="center"
                   v-model="usrUrineInfo.urgency_score"
                 />

                <van-field
                   autosize
                   rows="1"
                   label="备注"
                   type="textarea"
                   placeholder="请输入备注"
                   show-word-limit
                   label-align="center"
                   v-model="usrUrineInfo.tips"
                 />

                <van-field
                    center
                    clearable
                    label-align="center"
                    label="漏尿情况"
                  >
                  <template #input>
                    <van-radio-group v-model="usrUrineInfo.urine_leakage" direction="horizontal">
                      <van-radio name='1' shape='square' v-model="usrUrineInfo.urine_leakage">是</van-radio>
                      <van-radio name='0' shape='square' v-model="usrUrineInfo.urine_leakage">否</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>

                </van-cell-group>
                

              </div>

              <div v-else>
                <br>
                <time-picker 
                label="时间选择"
                placeholder="点击选择时间"
                v-model:time="usrDrinkInfo.time">
                </time-picker>
                <van-cell-group inset>
                
                <van-field
                  v-model="usrDrinkInfo.drink_type"
                  is-link
                  readonly
                  label="类型"
                  placeholder="点击选择类型"
                  label-align="center"
                  @click="picker.isDrinkTypePicker = true"
                />

                <van-field
                   autosize
                   rows="1"
                   label="饮水量"
                   type="textarea"
                   placeholder="请输入饮水量"
                   show-word-limit
                   label-align="center"
                   v-model="usrDrinkInfo.drink_volume"
                 />

                 <van-field
                   autosize
                   rows="1"
                   label="备注"
                   type="textarea"
                   placeholder="请输入备注"
                   show-word-limit
                   label-align="center"
                   v-model="usrDrinkInfo.tips"
                 />
                 </van-cell-group>
              </div>
          </van-dialog>

        </div>
        
        <van-dialog v-model:show="usrInfoModifyDialogShow" 
        title="修改作息时间" 
        @confirm="onModifySleepConfirm()"
        show-cancel-button>
          <br>
          <van-cell-group inset>
          <date-picker 
          label="日期选择"
          placeholder="点击选择日期"
          v-model:date="usrSleepInfo.date">
          </date-picker>

          <time-picker 
          label="入睡时间"
          placeholder="点击选择入睡时间"
          v-model:time="usrSleepInfo.sleep_in">
          </time-picker>

          <time-picker 
          label="起床时间"
          placeholder="点击选择起床时间"
          v-model:time="usrSleepInfo.get_up">
          </time-picker>
          </van-cell-group>
        </van-dialog>

        <div class="popupLayer">

        <van-popup v-model:show="picker.isDrinkTypePicker" round position="bottom">
          <van-picker
            :columns="drinkType"
            :default-index="picker.drinkTypePicker"
            @confirm="onDrinkTypePickerConfirm"
            @cancel="picker.drinkTypePickerClear()"
          />
        </van-popup>

        </div>
      </div>

    </div>
    <div v-else style="padding-top: 20%;text-align: center">
      <van-loading size="40px" />
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { ref, reactive } from 'vue';
import TimePicker from '@/components/TimePicker'
import DatePicker from '@/components/DatePicker'
import objCopy from '@/use/useObjectCopy'
import useToastRes from '@/use/useToastResponse'
import { Dialog } from 'vant';

export default {
  name: 'UriLogByScan',
  components : {
    TimePicker,
    DatePicker
  },
  setup(){
    const log_list = reactive({
      patient_id: '',
      date : '',
      drink_list : [],
      get_up : '',
      sleep_in : '',
      uri_list: [],
      patientInfo : {
        name: '',
        bri: 0,
        sexy: ''
      }
    })

    const usrInfoModifyDialogShow = ref(false)

    const isModify = ref(false)
    const modifyIndex = ref(-1)

    const tabNumber = ref(0)
    const dialogDisplayType = ref([false, false])
    const dialogDisplayTitle = ['新增排尿', '新增饮水']
    const dialogModifyTitle = ['修改排尿', '修改饮水']
    const checklist = ['否', '是']
    const drinkType = ['饮料', '白开水', '矿泉水']

    const picker = reactive({
      drinkTypePicker : 0, // the index of drinkType Array
      isDrinkTypePicker : false,
      drinkTypePickerClear : () => {
        picker.drinkTypePicker = 0
        picker.isDrinkTypePicker = false
      }

    })

    const usrSleepInfo = reactive({
      date: '',
      get_up : '',
      sleep_in : '',
      patient_id : ''
    })

    const usrUrineInfo = reactive({
      id: -1,
      time : '',
      urine_volume : '',
      urgency_score : '',
      tips: '',
      urine_leakage : '-1',

      selfClear : () => {
        usrUrineInfo.id = -1
        usrUrineInfo.time = ''
        usrUrineInfo.urine_volume = ''
        usrUrineInfo.urgency_score = ''
        usrUrineInfo.tips = ''
        usrUrineInfo.urine_leakage = '-1'
      }
    })

    const usrDrinkInfo = reactive({
      id: -1,
      time : '',
      drink_type : '',
      drink_volume : '',
      tips: '',

      selfClear : () => {
        usrDrinkInfo.id = -1
        usrDrinkInfo.time = ''
        usrDrinkInfo.drink_type = ''
        usrDrinkInfo.drink_volume = ''
        usrDrinkInfo.tips = ''
      }
    })

    const onClickAddBtn =  () => {
      const type = tabNumber.value
      
      switch ( type ){
        case 0 :
          usrUrineInfo.selfClear()
          break;

        case 1 :
          usrDrinkInfo.selfClear()
          break;

        default:
          break;
      }
      dialogDisplayType.value[tabNumber.value] = true
    } 

    const onClickItemModifyBtn = (index) => {
      const type = tabNumber.value
      dialogDisplayType.value[tabNumber.value] = true
      isModify.value = true
      modifyIndex.value = index

      switch( type ){
        case 0:
        {
          objCopy(usrUrineInfo, log_list.uri_list[index])
          usrUrineInfo.urine_leakage += ''
          break;
        }

        case 1:
        {
          objCopy(usrDrinkInfo, log_list.drink_list[index])
          picker.drinkTypePicker = drinkType.indexOf(usrDrinkInfo.drink_type)
          break;
        }

        default:
          break;
      }
      
    }
    
    const onClickSleepModifyBtn = () => {
      objCopy(usrSleepInfo, log_list)

      usrInfoModifyDialogShow.value = true
    }

    const onDrinkTypePickerConfirm = (value) => {
      usrDrinkInfo.drink_type = value
      
      picker.drinkTypePickerClear()
    }

    return {
      /* normal var */
      checklist,
      dialogDisplayTitle,
      dialogModifyTitle,
      drinkType,

      /* ref var */
      tabNumber,
      isModify,
      modifyIndex,
      dialogDisplayType,
      usrInfoModifyDialogShow,
      log_list,

      /* function */
      onClickAddBtn,
      onClickItemModifyBtn,
      onDrinkTypePickerConfirm,
      onClickSleepModifyBtn,

      /* ref obj */
      usrUrineInfo,
      usrDrinkInfo,
      usrSleepInfo,
      picker
    }
  },
  mounted() {
    let app = this;

    this.loading = true
    this.axios({
      method: 'post',
      // data:{id:this.$route.params.id},
      url: this.$store.state.base_url+'/api/oab_client/patient/uri/'+this.$route.params.id
    }).then(function (res){
      objCopy(app.log_list, res.data.rec)
      console.log(res.data)

      app.axios({
        method: 'post',
        url:  app.$store.state.base_url+'/api/oab_client/patient/info',
        data:{id:app.log_list.patient_id}
      }).then(function (res){
        // console.log(res)
        objCopy(app.log_list.patientInfo, res.data.patient)
        app.loading = false
      })
    })
  },
  data() {
    return {
      loading:false,
      // log_list:[],
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onClickRight:function (){
      // router.push({ name: 'patient_add'})
    },
    toPatients:function (e){

    },
    toPatientsReadOnly:function (e){
      console.log(e)
      this.$router.push({path: '/patient/infoReadOnly/'+ e});
    },
    reloadOrgData:function (e) {
      if (e == 1) {
        this.activeIds = [];
      } else if(e == 4) {
        this.select_zd = [];
      }
    },
    onModifySleepConfirm:function () {
      let app = this
      this.axios({
        method: 'post',
        data: {
          id: this.$route.params.id,
          patient_id: this.usrSleepInfo.patient_id,
          date : this.usrSleepInfo.date,
          get_up : this.usrSleepInfo.get_up,
          sleep_in : this.usrSleepInfo.sleep_in,
        },
        url: this.$store.state.base_url + '/api/oab_client/patient/uri_update'
      }).then(function (res){
            // console.log(res.data)
            app.refreshUrineRecord()
            useToastRes(res.data, '修改作息时间')
          })

      this.usrInfoModifyDialogShow = true
    },
    /* 0 ---- urine */
    /* 1 ---- drink */
    onAddBtnConfirm: function () {
      const type = this.tabNumber
      let app = this
      this.isModify = false
      this.modifyIndex = -1
      
      switch ( type )
      {
        case 0 :
        {
          this.axios({
            method: 'post',
            data: {
              id: this.$route.params.id,
              time : this.usrUrineInfo.time,
              urine_volume : this.usrUrineInfo.urine_volume,
              urgency_score : this.usrUrineInfo.urgency_score,
              urine_leakage : this.usrUrineInfo.urine_leakage,
              tips : this.usrUrineInfo.tips
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/log/update'
          }).then(function (res){
            const code = res.data.code
            useToastRes(res.data, '添加排尿信息')
            app.refreshUrineRecord()
            // console.log(res.data)
          })
          
          this.usrUrineInfo.selfClear()
          
          break;
        }
          
        case 1 :
        {
          this.axios({
            method: 'post',
            data: {
              id: this.$route.params.id,
              time: this.usrDrinkInfo.time,
              drink_type : this.usrDrinkInfo.drink_type,
              drink_volume : this.usrDrinkInfo.drink_volume,
              tips : this.usrDrinkInfo.tips
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/drink/update'
          }).then(function (res){
            useToastRes(res.data, '添加饮水信息')
            app.refreshUrineRecord()
            // console.log(res.data)
          })
          
          this.usrDrinkInfo.selfClear()

          break;
        }

        default : 
          break;
      }

    },

    onModifyBtnConfirm :function () {
      const type = this.tabNumber
      let app = this
      this.isModify = false

      switch( type ){
        case 0 :
        {
          // console.log(index)
          // console.log(this.usrUrineInfo.id)
          this.axios({
            method: 'post',
            data: {
              id: this.$route.params.id,
              log_id: this.usrUrineInfo.id,
              time : this.usrUrineInfo.time,
              urine_volume : this.usrUrineInfo.urine_volume,
              urgency_score : this.usrUrineInfo.urgency_score,
              urine_leakage : this.usrUrineInfo.urine_leakage,
              tips : this.usrUrineInfo.tips
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/log/update'
          }).then(function (res){
            useToastRes(res.data, '修改排尿信息')
            // console.log(res.data)
            app.refreshUrineRecord()
          })
          break;
        }

        case 1:
        {
          this.axios({
            method: 'post',
            data: {
              id: this.$route.params.id,
              log_id: this.usrDrinkInfo.id,
              time : this.usrDrinkInfo.time,
              drink_type : this.usrDrinkInfo.drink_type,
              drink_volume : this.usrDrinkInfo.drink_volume,
              tips: this.usrDrinkInfo.tips,
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/drink/update'
          }).then(function (res){
            useToastRes(res.data, '修改饮水信息')
            app.refreshUrineRecord()
            // console.log(res.data)
          })
          break;
        }

        default:
          break;
      }
    },

    onRemoveUrineBtnClicked:function (usrUrineInfo) {
      let app = this
      Dialog.confirm({
      title: '是否删除该条记录',
      message:
        `时间:${usrUrineInfo.time}\n尿量:${usrUrineInfo.urine_volume}\n尿急评分:${usrUrineInfo.urgency_score}\n漏尿情况:${app.checklist[usrUrineInfo.urine_leakage]}`
      })
      .then(() => {
        // on confirm
        this.axios({
            method: 'post',
            data: {
              log_id: usrUrineInfo.id,
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/log/delete'
          }).then(function (res){
            useToastRes(res.data, '删除排尿记录')
            app.refreshUrineRecord()
            // console.log(res.data)
          })
      })
      .catch(() => {
        // on cancel
        console.log('onRemoveUrineBtnClicked cancel')
      });
    },

    onRemoveDrinkBtnClicked:function (usrDrinkInfo) {
      let app = this
      console.log(usrDrinkInfo)
      Dialog.confirm({
      title: '是否删除该条记录',
      message:
        `时间:${usrDrinkInfo.time}\n类型:${usrDrinkInfo.drink_type}\n饮水量:${usrDrinkInfo.drink_volume}`
      })
      .then(() => {
        // on confirm
        this.axios({
            method: 'post',
            data: {
              log_id: usrDrinkInfo.id,
            },
            url: this.$store.state.base_url + '/api/oab_client/patient/uri/drink/delete'
          }).then(function (res){
            useToastRes(res.data, '删除饮水记录')
            app.refreshUrineRecord()
            // console.log(res.data)
          })
      })
      .catch(() => {
        // on cancel
        console.log('onRemoveDrinkBtnClicked cancel')
      });
    },

    refreshUrineRecord: function () {
    let app = this;
    this.loading = true

    this.axios({
      method: 'post',
      // data:{id:this.$route.params.id},
      url: this.$store.state.base_url+'/api/oab_client/patient/uri/'+this.$route.params.id
    }).then(function (res){
      objCopy(app.log_list, res.data.rec)
      // console.log(res.data)
      app.loading = false
    })
    }
  }

}
</script>

<style>
  .modify{
    float: right;
  }
  .usrInfo span:not(:first-child), .urineItem span:not(:first-child):not(.modify), .drinkItem span:not(:first-child):not(.modify){
    padding: 10px;
  }
  .urineItem div, .drinkItem div, .usrInfo div{
    margin-top: 5px;
  }
  .about{
    background-color: white;
  }
</style>