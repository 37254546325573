<template>
  <div class="about">

    <van-nav-bar
        title="排尿日记"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
      <template #right>
        <van-icon name="plus" color="black"  size="20px"/>
      </template>
    </van-nav-bar>
    

    <div v-if="!loading">
      <div v-if="log_list_cache.length == 0" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        暂无数据
      </div>
      <div v-else>
        <van-dropdown-menu>
        <van-dropdown-item :title="dropdownDate.startTitle" ref="isStartDropdown">
          <van-datetime-picker
            v-model="dropdownDate.startDate"
            type="date"
            title="选择起始日期"
            
            @confirm='onStartDateConfirm'
            @cancel='onStartDateCancel'
          />
        </van-dropdown-item>

        <van-dropdown-item :title="dropdownDate.endTitle" ref='isEndDropdown'>
          <van-datetime-picker
            v-model="dropdownDate.endDate"
            type="date"
            title="选择结束日期"
            @confirm='onEndDateConfirm'
            @cancel='onEndDateCancel'
          />
        </van-dropdown-item>
        </van-dropdown-menu>

        <div class="info" v-for="item in log_list" :key="item.id">
          <div>日期:{{item.date}}</div>
          <div>
            <div>
            <span>入睡:{{item.sleep_in}}</span>
            <span>|</span>
            <span>起床时间:{{item.get_up}}</span>
            <van-button type="default" style="float:right;margin-top:-10px" @click="onClickCheckDetail(item.id)">查看详情</van-button>
            </div>
            
            <!-- <span>入睡:{{item.sleep_in}}</span>
            <span>|</span>
            <span>起床时间:{{item.get_up}}</span> -->
          </div>
          <div>
            <span>排尿次数:{{item.uri_count}}</span>
            <span> 饮水次数:{{item.drink_count}}</span>
          </div>
          <!-- <div><van-button type="default" @click="onClickCheckDetail(item.id)">查看详情</van-button></div> -->
          <hr>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 20%;text-align: center">
      <van-loading size="40px" />
    </div>

    <div class="popupLayer">
      <van-dialog v-model:show="isRecordDialogShow" 
      title="添加排尿记录" 
      :show-cancel-button="false"
      :show-confirm-button="false"
      :closeOnClickOverlay="true">
        <date-picker label='日期' placeholder="点击输入日期" v-model:date="recordDate"></date-picker>
           <div style="text-align:center;margin:5px auto"><van-button type="default"  >二维码邀请</van-button></div>
           <div style="text-align:center;margin:5px auto"><van-button type="default"  @click="onAddRecordConfirm">手动添加</van-button></div>
      </van-dialog>
    </div>
    <!-- <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block" />
      </div>
    </van-overlay> -->

  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import DatePicker from '@/components/DatePicker'
import { reactive, ref } from 'vue';
import { isMinDate, isMaxDate, getDateFromString } from '../../use/useDateCmp'
import { useObjectDeepCopy } from '@/use/useObjectCopy'
import useToastRes from '@/use/useToastResponse'


export default {
  name: 'UriLog',
  setup() {
    const isStartDropdown = ref(null)
    const isEndDropdown = ref(null)
    const startTitieDefault = '起始日期'
    const endTitieDefault = '结束日期'

    const dropdownDate = reactive({
      startDate : new Date(2022, 0, 1),
      endDate : new Date(2022, 0, 1),
      minDate : new Date(2022, 0 ,1),
      maxDate : new Date(2022, 0, 1),
      startTitle : startTitieDefault,
      endTitle : endTitieDefault,

      startDateClear : () => {
        dropdownDate.startTitle = startTitieDefault
      },
      endDateClear : () => {
        dropdownDate.endTitle = endTitieDefault
      }
    })

    return {
//normal var
      startTitieDefault,
      endTitieDefault,

//ref var
      isStartDropdown,
      isEndDropdown,

//reactive obj
      dropdownDate
    }
  },
  mounted() {
    let app = this;

    //获取用户ID
    // console.log(this.$route.params.id)

    this.axios({
      method: 'post',
      data:{id:this.$route.params.id},
      url:  this.$store.state.base_url+'/api/oab_client/patient/uri_list',
    }).then(function (res) {
      console.log(res.data)
      app.log_list = res.data.uri_list;
      app.log_list_cache = useObjectDeepCopy(app.log_list)
      app.loading = false;
    }).catch(function (error) {
      console.log(error)
    });
  },
  components: {
    DatePicker
  },
  data() {
    // usr
    return {
      recordDate:'2022-01-01',
      isRecordDialogShow:false,
      loading:true,
      log_list:[],
      log_list_cache:[]
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onClickRight:function (){
      //添加记录
      this.isRecordDialogShow = true
    },
    onStartDateConfirm:function () {
        this.dropdownDate.startTitle = this.dropdownDate.startDate.Format('yyyy-MM-dd')
        // reset info array
        this.log_list.splice(0, this.log_list.length)
        let leftDateDown = this.dropdownDate.startDate

        if( this.dropdownDate.endTitle !== this.endTitieDefault){
          let leftDateUp = getDateFromString(this.dropdownDate.endTitle)

          for (let index = 0; index < this.log_list_cache.length; index++) {
            const element = this.log_list_cache[index];
            let rightDate = getDateFromString(element.date)
            if( isMinDate(leftDateUp, rightDate) || isMaxDate(leftDateDown, rightDate)){
              continue
            }
            let item = this.log_list_cache[index]
            this.log_list.push(item)
          }
        }
        else{
          for (let index = 0; index < this.log_list_cache.length; index++) {
            const element = this.log_list_cache[index];
            let rightDate = getDateFromString(element.date)
            if( isMaxDate(leftDateDown, rightDate) ){
              continue
            }
            let item = this.log_list_cache[index]
            this.log_list.push(item)
          }
        }
        
        this.isStartDropdown.toggle(false)
    },
    onEndDateConfirm:function () {
      this.dropdownDate.endTitle = this.dropdownDate.endDate.Format('yyyy-MM-dd')
      // reset info array
      this.log_list.splice(0, this.log_list.length)
      let leftDateDown = this.dropdownDate.endDate

      if( this.dropdownDate.startTitle !== this.startTitieDefault){
        let leftDateUp = getDateFromString(this.dropdownDate.startTitle)

        for (let index = 0; index < this.log_list_cache.length; index++) {
          const element = this.log_list_cache[index];
          let rightDate = getDateFromString(element.date)
          if( isMaxDate(leftDateUp, rightDate) || isMinDate(leftDateDown, rightDate)){
            continue
          }
          let item = this.log_list_cache[index]
          this.log_list.push(item)
        }
      }
      else{
        for (let index = 0; index < this.log_list_cache.length; index++) {
          const element = this.log_list_cache[index];
          let rightDate = getDateFromString(element.date)
          if( isMinDate(leftDateDown, rightDate) ){
            continue
          }
          let item = this.log_list_cache[index]
          this.log_list.push(item)
        }
      }

      this.isEndDropdown.toggle(false)
    },
    onStartDateCancel:function () {
        this.isStartDropdown.toggle()
        this.dropdownDate.startDateClear()
        if( this.dropdownDate.endTitle != this.endTitieDefault ){
          this.onEndDateConfirm()
        }
    },
    onEndDateCancel:function () {
        this.dropdownDate.endDateClear()
        this.isEndDropdown.toggle()
        if( this.dropdownDate.startTitle != this.startTitieDefault ){
          this.onStartDateConfirm()
        }
    },
    onAddRecordConfirm:function (){
      let app = this
      this.axios({
        method: 'post',
        data:{
          date: this.recordDate,
          patient_id: this.$route.params.id,
        },
        url:  this.$store.state.base_url+'/api/oab_client/patient/uri_update',
        showCalendar:false
      }).then(function (res) {
        // console.log(res.data)
        useToastRes(res.data, '添加记录')
        if( res.data.code == 200)
          app.refreshDateRecord()
      }).catch(function (error) {
        console.log('onAddRecordConfirm()',error)
      });
    },
    onClickCheckDetail: function(id){
      router.push({ name: 'uri_log', params: { id: id }});
    },
    refreshDateRecord: function(){
      let app = this
      this.loading = true
      this.axios({
        method: 'post',
        data:{id:this.$route.params.id},
        url:  this.$store.state.base_url+'/api/oab_client/patient/uri_list',
      }).then(function (res) {
        console.log(res.data)
        app.log_list = res.data.uri_list;
        app.log_list_cache = useObjectDeepCopy(app.log_list)

        app.loading = false
        app.isRecordDialogShow = false
      }).catch(function (error) {
        console.log(error)
        app.loading = false
        app.isRecordDialogShow = false
      });
    }
  }

}
</script>

<style>
.info{
  padding: 10px;
  background-color: white;
}
.info div:not(:first-child){
  margin-top: 5px;
} 
.info span:not(:first-child){
  margin-left: 20px;
}
</style>
