import { isReactive, toRaw } from "vue"

/**
 * 用于复制src对象中的值至dst对象
 * 
 * dst可以是reactive对象
 * @param {Object}dst
 * src只能是Js原生Object对象
 * @param {Object}src
 */
export default function useObjectCopy(dst, src){
    let obj = {}
    
    obj = isReactive(dst) ? toRaw(dst) : dst
    Object.keys(obj).forEach( (value) => {
        if ( undefined != src[value] ){
          dst[value] = src[value]
        } 
    } )
}

/**
 * 用于复制src对象(深拷贝)
 * 
 * src可以是Js原生Object对象或reactive对象
 * @param {Object}src
 */
 export function useObjectDeepCopy(src){
  let obj = JSON.stringify(src)
  let newObj = JSON.parse(obj)
  
  return newObj
}