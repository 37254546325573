<template>
  <!-- <div> -->
    <van-cell-group inset>
    <van-field
      v-model="date"
      is-link
      readonly
      name="datePicker"
      :label='label'
      :placeholder='placeholder'
      :border='true'
      label-align="center"
      @click="onfieldClicked"
    />
    <teleport to='.popupLayer'>
    <van-popup v-model:show="picker.isShowDatePicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="picker.datePicker"
        @confirm="ondatePickerConfirm"
        @cancel="picker.datePickerClear()"
      />
    </van-popup>
    </teleport>
    </van-cell-group>
  <!-- </div> -->
</template>

<script>
import { reactive } from "vue";

export default {
  name: "DatePciker",
  props : {
      label : String,
      placeholder : String,
      date : String
  },
  setup(props, context) {
    const picker = reactive({
        isShowdatePicker: false,
        datePicker: new Date(2022, 1 ,1),  
        datePickerClear: () => {
          picker.isShowDatePicker = false;
          picker.datePicker.setSimpleDate(2022,1,1);
        },

        setDate : () => {
            let date = props.date
            let dateArr = date.split('-')
            picker.datePicker.setSimpleDate(dateArr[0], dateArr[1], dateArr[2])
         }, 
         getDateString : () => {
            return picker.datePicker.Format('yyyy-MM-dd')
         }
    });

    const onfieldClicked = function() {
        if ( props.date !== '' ){
            picker.setDate()
        } 
        
        picker.isShowDatePicker = true;
    }

    const ondatePickerConfirm = function() {
        context.emit('update:date', picker.getDateString())
        picker.datePickerClear();
    }

    return {
      picker,
      ondatePickerConfirm,
      onfieldClicked
    };
  }

}
</script>

<style>
</style>