<template>
  <div>
    <van-cell-group inset>
    <van-field
      v-model="time"
      is-link
      readonly
      name="datetimePicker"
      :label='label'
      :placeholder='placeholder'
      label-align="center"
      @click="onfieldClicked"
    />
    <teleport to='.popupLayer'>
    <van-popup v-model:show="picker.isShowTimePicker" position="bottom">
      <van-datetime-picker
        type="time"
        v-model="picker.timePicker"
        @confirm="ontimePickerConfirm"
        @cancel="picker.timePickerClear()"
      />
    </van-popup>
    </teleport>
    </van-cell-group>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  name: "TimePciker",
  props : {
      label : String,
      placeholder : String,
      time : String
  },
  setup(props, context) {
    const picker = reactive({
      isShowTimePicker: false,
      timePicker: props.time,

      timePickerClear: () => {
        picker.isShowTimePicker = false;
        picker.timePicker = "00:00";
      }
    });

    const onfieldClicked = function() {
        if ( props.time !== '' ){
            picker.timePicker = props.time
        } 
        
        picker.isShowTimePicker = true;
    }

    const ontimePickerConfirm = function() {
        context.emit('update:time', picker.timePicker)
        picker.timePickerClear();
    }

    return {
      picker,
      ontimePickerConfirm,
      onfieldClicked
    };
  }

};
</script>

<style>
</style>