/**
 * 判断左日期的时间是否小于右时间的值
 * 可理解为 leftDate < rightDate
 * @param {Date} leftDate
 * @param {Date} rightDate
 * @returns {boolean}
 */

export function isMinDate(leftDate, rightDate){
    return leftDate.getTime() < rightDate.getTime() ? true : false
}

/**
 * 判断左日期的时间是否大于右时间的值
 * 可理解为 leftDate > rightDate
 * @param {Date} leftDate
 * @param {Date} rightDate
 * @returns {boolean}
 */

export function isMaxDate(leftDate, rightDate){
    return leftDate.getTime() > rightDate.getTime() ? true : false
}

/**
 * 通过yyyy-mm-dd格式字符串创建Date对象
 * @param {String} dateString
 * @returns {Date}
 */

export function getDateFromString(dateString){
    let dateArr = dateString.split('-')
    return new Date(dateArr[0], dateArr[1]-1, dateArr[2])
}