import { Toast } from "vant";
/**
 * 用于提示用户当前网络请求的结果
 * @param {Object} data - axios 返回的res.data数据
 * @param {String} describe - 本次网络请求执行的任务描述
 */
export default function useToastResponse(data, describe){
    const code = data.code
    switch(code){
        case 200:{
            Toast.success(describe + '成功')
            break;
        }

        case 404:{
            Toast.fail('404找不到该接口')
            break;
        }

        case 0:{
            Toast.fail(data.message)
            break;
        }

        default:{
            Toast.fail('未知的错误:'+code)
            break;
        }
    }
}